import Cookies                 from "js-cookie";
import { useEffect, useState } from "react";

import { DEFAULT_ERROR_MSG } from "_common/string_config";
import toast                 from "_common/toast";
import { createUrl }         from "_common/utils/seo";
import { useSetWebviewURL }  from "_jotai/index";

import { useGetLoggedIn } from "./auth";

export async function createWebviewURL () {
	try {
		const auth = await getAuth ();

		auth.deviceType = "web";
		auth.deviceId   = "some-random-id";
		auth.appVersion = "1.0.5";
		auth.platform   = "rooterWeb";

		delete auth.userRoles;
		delete auth.isVerified;
		delete auth.locale;
		delete auth.isNew;

		return auth;

	} catch ( error ) {
		// console.log ( "=============  error:", error );
		toast.error ( DEFAULT_ERROR_MSG );
	}
}

async function getAuth () {
	try {
		const cookie = Cookies.get ( "user_auth" );

		const jsonValue = JSON.parse ( decodeURIComponent ( cookie ) );

		return jsonValue;
	} catch ( error ) {
		toast.error ( DEFAULT_ERROR_MSG );
	}
}

export const shopBaseURL = "https://shop.rooter.gg";

export const useShopWebview = ( { productId } ) => {
	const isLoggedIn                      = useGetLoggedIn ();
	const [ productLink, setProductLink ] = useState ( `${ shopBaseURL }/product/${ productId }` );

	/**
	 * this function creates a webview url for shop to carry forward the login system
	 * if the user is logged in then we append the queryparams else we just open the
	 * regular link
	*
	* @async
	 * @returns {Promise<void>}
	*/
	async function getQueryParam () {
		try {
			const params = await createWebviewURL ();

			return params;
		} catch ( err ) {
			return "";
		}
	}

	async function createProductURL () {
		const productURL = `${ shopBaseURL }/product/${ productId }`;

		const _url = new URL ( productURL );

		// only add login query params if user is logged in
		if ( isLoggedIn ) {
			const params = await getQueryParam ();

			Object.entries ( params ).forEach ( ( [ key, value ] ) => {
				_url.searchParams.append ( key, value );
			} );

		}

		const finalURL = await createUrl ( _url, { source: "home_page", medium: "web", campaign: productId } );

		setProductLink ( finalURL );

	}

	useEffect ( () => {
		createProductURL ();
	}, [ isLoggedIn ] );

	return { productLink };
};
